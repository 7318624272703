import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import deposits from "../../app/components/transaction/Deposits";
import css from "../css/Addcase.module.css";
import "../css/Loader.css";
import Swal from "sweetalert2";
import { decrypt } from "../utils/cryptoUtils";



const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;

if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
} else {
    baseUrl = beckendLiveApiUrl;
}

const Addcase = ({ walletUpdate }) => {
    const history = useHistory();
    let method = useRef();
    let checkInterval;
    const [userAllData, setUserAllData] = useState();
    const [submitBtn, setSubmitBtn] = useState(true);

    const [global, setGlobal] = useState(100);
    const [next, setNext] = useState(1);
    const [process, setProcess] = useState(false);
    const [isMobile, setMobile] = useState(false);

    const [isCashFreeActive, setCashFreeActive] = useState(false);
    
    const [isDecentroActive, setDecentroActive] = useState(false);
    const [isManualUPIQR, setManualUPIQR] = useState(false);
    const [upiId, setupiId] = useState('');
    
    const [isManualPaymentActive, setManualPaymentActive] = useState(false);
    const [isPhonePeActive, setPhonePeActive] = useState(true);
    const [isRazorPayActive, setRazorPayActive] = useState(false);
    const [isUpiGatewayActive, setUpiGatewayActive] = useState(false);
    const [RazorPayAccountName, setAccountName] = useState("test");

    const [qrCode, setQrCode] = useState();
    const [walletOption, setWalletOption] = useState("airtel");
    const [bankCode, setBankCode] = useState(3003);

    const [account_mail_id, setAccount_mail_id] = useState();
    const [account_name, setAccount_name] = useState();
    const [accountPhone, setAccountPhone] = useState();

    const [scrnshot, setScrnshot] = useState("");
    const [scrnshot1, setScrnshot1] = useState("");
    const [upiUtrNum, setupiUtrNum] = useState("");

    // eslint-disable-next-line
    const handleChange = (e) => {
        setScrnshot1(URL.createObjectURL(e.target.files[0]));
        setScrnshot(e.target.files[0]);
    };

    // 1. Manual Payment mode 
    // console.log(userAllData)

    const ManualPayment = async (e) => {
        setSubmitBtn(false)
        e.preventDefault();
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };
        const formData = new FormData();

        formData.append("Transaction_Screenshot", scrnshot);
        formData.append("_id", userAllData._id);
        formData.append("amount", global);
        formData.append("TransactionId", upiUtrNum);


        // Log FormData key-value pairs
        // formData.forEach((value, key) => {
        //     console.log(`${key}: ${value}`);
        // });



        const response = await axios.post(baseUrl + `manual/deposit/txn`, formData, { headers });

        if (response.data.status === "PAID") {

            setProcess(false);
            Swal.fire({
                title: response.data.txn_msg,
                icon: "success",
                confirmButtonText: "OK",
            });
            walletUpdate();

        } else if (response.data.status === "Pending") {
            Swal.fire({
                title: response.data.txn_msg,
                icon: "danger",
                confirmButtonText: "OK",
            });
        } else {
            setProcess(false);
            Swal.fire({
                title: response.data.msg,
                icon: "danger",
                confirmButtonText: "OK",
            });
        }
        setSubmitBtn(true)
    };

    //Function to load razorpay script for the display of razorpay payment SDK.
    function loadRazorpayScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    //function will get called when clicked on the pay button.
    async function displayRazorpayPaymentSdk(
        channel,
        method,
        upiMethod,
        razorpay
    ) {
        const res = await loadRazorpayScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. please check are you online?");
            return;
        }
        //setProcess(true);
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        // creating a new order and sending order ID to backend
        const response = await axios.post(
            baseUrl + `user/razorpay_order`,
            {
                amount: global,
                channel: channel,
                payment_method: method,
                provider: walletOption,
                bankCode: bankCode,
                account_name: account_name,
                payment_gatway: razorpay,
            },
            { headers }
        );

        if (!response) {
            alert("Server error. please check are you online?");
            return;
        }

        //console.log(response.data.orderdata);
        // Getting the order details back
        let order_id = response.data.txnID;
        let order_token = response.data.orderdata.id;
        const data = response.data.orderdata;
        const options = {
            // key: RazorPayKey,
            name: RazorPayAccountName,
            description: "Skill Based Game Tournament",
            order_id: data.id,
            prefill: {
                name: account_name,
                email: account_mail_id,
                contact: accountPhone,
            },
            handler: async (response) => {
                //console.log(response)
                try {
                    const paymentId = response.razorpay_payment_id;

                    //const url = baseUrl+`rozarpay/capture/${paymentId}`;
                    //const captureResponse = await axios.post(url, {},{headers})
                    //console.log(captureResponse.data);
                    checkrazorpaydeposit(order_id, order_token, "SUCCESS", paymentId);
                } catch (err) {
                    checkrazorpaydeposit(order_id, order_token, "FAILED");
                    console.log(err);
                }
            },
            theme: {
                color: "#686CFD",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const checkrazorpaydeposit = (
        order_id,
        order_token,
        order_status,
        paymentId
    ) => {
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };
        axios
            .post(
                baseUrl + `razorpaydesposit/response`,
                { order_id, order_token, order_status, paymentId },
                { headers }
            )
            .then((res) => {
                const icon = res.data.status === "PAID" ? "success" : "danger";
                const title =
                    res.data.status === "PAID"
                        ? "Deposit submited successfully"
                        : "Transaction Failed";
                history.push("/landing");
                setTimeout(() => {
                    Swal.fire({
                        title: title,
                        icon: icon,
                        confirmButtonText: "OK",
                    });
                }, 1000);
            })
            .catch((e) => {
                if (e.response?.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("token");
                    window.location.reload();
                }
            });
    };

    //use for decentrodepositeupi
    const decentroDepositeUpi = (channel, method, upiMethod, decentropay) => {
        //account_name
        //account_mail_id
        //alert(account_name);
        //if(account_name && account_mail_id){
        setProcess(true);
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.post(baseUrl + `user/decentrodepositeupi`,
            {
                amount: global,
                channel: channel,
                payment_method: method,
                provider: walletOption,
                bankCode: bankCode,
                account_name: account_name,
                payment_gatway: decentropay,
            },
            { headers }
        )
            .then((res) => {

                if (res.data.data.status === "SUCCESS") {
                    // eslint-disable-next-line
                    let order_id = res.data.txnID;
                    // eslint-disable-next-line
                    let order_token = res.data.data.decentroTxnId;
                    //setProcess(false);
                    window.open(res.data.data.data.generatedLink);
                    setTimeout(() => {
                        //checkdecentrodeposit(order_id, order_token)
                        setProcess(false);
                    }, 30000);
                } else {
                    setProcess(false);
                    Swal.fire({
                        title: res.data.data.msg,
                        icon: "danger",
                        confirmButtonText: "OK",
                    });
                }
            })
            .catch((e) => {
                alert(e);
            });

    };

    //use for decentrodepositeupi
    const phonePeDepositeUpi = (channel, method, upiMethod, phonepe) => {
        setProcess(true);
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
        };
        axios.post(baseUrl + `user/phonedepositeapi`,
            {
                // payment_gatway: phonepe,
                _id: userAllData._id,
                MUID: "MUID" + Date.now(),
                transactionId: 'T' + Date.now(),
                Phone: userAllData.Phone,
                amount: global,
            },
            { headers }
        )
            .then((res) => {
                window.location.href = res.data;
            })
            .catch((e) => {
                alert(e);
            });
    };
    // eslint-disable-next-line
    const checkphonepedeposit = (order_id, order_token) => {
        // console.log(order_token);
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.post(baseUrl + `phonepestatus/response`, { order_id, order_token }, { headers })
            .then(
                (res) => {
                    const icon = res.data.status === "PAID" ? "success" : "danger";
                    const title = res.data.status === "PAID" ? "Deposit submited successfully" : "Transaction in failed";
                    if (res.data.status === "Pending") {
                        setTimeout(() => {
                            checkphonepedeposit(order_id, order_token);
                            Swal.fire({
                                title: title,
                                icon: icon,
                                confirmButtonText: "OK",
                            });
                        }, 6000);
                    } else {
                        history.push("/");
                        setTimeout(() => {
                            Swal.fire({
                                title: title,
                                icon: icon,
                                confirmButtonText: "OK",
                            });
                        }, 1000);
                    }
                }
            ).catch(
                (e) => {
                    if (e.response?.status === 401) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("token");
                        window.location.reload();
                    }
                }
            );
    };

    const depositUpiGateway = (channel, method, upiMethod, upigateway) => {
        setProcess(true);
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.post(baseUrl + `user/depositeupi`,
            {
                amount: global,
                channel: channel,
                payment_method: method,
                provider: walletOption,
                bankCode: bankCode,
                account_name: account_name,
                account_mail_id: account_mail_id,
                payment_gatway: upigateway,
            },
            { headers }
        )
            .then((res) => {
                if (res.data.data.status) {
                    let order_id = res.data.txnID;
                    let order_token = res.data.data.data.order_id;
                    window.open(res.data.data.data.payment_url);
                    setTimeout(() => {
                        checkupideposit(order_id, order_token);
                        setProcess(false);
                    }, 30000);
                } else {
                    setProcess(false);
                    Swal.fire({
                        title: res.data.data.msg,
                        icon: "danger",
                        confirmButtonText: "OK",
                    });
                }
            })
            .catch((e) => {
                alert(e);
            });
    };

    const checkupideposit = (order_id, order_token) => {
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.post(
            baseUrl + `depositupipay/response`, { order_id, order_token }, { headers }
        )
            .then((res) => {
                const icon = res.data.status === "PAID" ? "success" : "danger";
                const title =
                    res.data.status === "PAID" ? "Deposit submited successfully" : "Transaction Failed";
                history.push("/landing");
                setTimeout(() => {
                    Swal.fire({
                        title: title,
                        icon: icon,
                        confirmButtonText: "OK",
                    });
                }, 1000);
            })
            .catch((e) => {
                if (e.response?.status === 401) {
                    // localStorage.removeItem('token');
                    // localStorage.removeItem('token');
                    window.location.reload();
                }
            });
    };




    //use for cashfree gatway
    const deposit = (channel, method, upiMethod) => {
        setProcess(true);
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.post(baseUrl + `user/deposite`,
            {
                amount: global,
                channel: channel,
                payment_method: method,
                provider: walletOption,
                bankCode: bankCode,
            },
            { headers }
        )
            .then((res) => {
                if (res.data.data.payment_method === "app") {
                    window.location.href = res.data.data.data.url;
                    checkInterval = setInterval(
                        (ID) => {
                            checkout(ID);
                        },
                        10000,
                        res.data.txnID
                    );
                } else if (res.data.data.payment_method === "netbanking") {
                    window.location.href = res.data.data.data.url;
                    checkInterval = setInterval(
                        (ID) => {
                            checkout(ID);
                        },
                        10000,
                        res.data.txnID
                    );
                } else if (
                    res.data.data.channel === "link" &&
                    res.data.data.payment_method === "upi"
                ) {
                    checkInterval = setInterval(
                        (ID) => {
                            checkout(ID);
                        },
                        10000,
                        res.data.txnID
                    );
                    window.location.href = res.data.data.data.payload[upiMethod];
                } else if (
                    res.data.data.channel === "qrcode" &&
                    res.data.data.payment_method === "upi"
                ) {
                    setQrCode(res.data.data.data.payload.qrcode);
                    setProcess(false);
                    setNext(3);
                    checkInterval = setInterval(
                        (ID) => {
                            checkout(ID);
                        },
                        10000,
                        res.data.txnID
                    );
                }
            })
            .catch((e) => {
                alert(e);
            });
    };

    const checkout = (paymentID) => {
        const access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.post(
            baseUrl + `checkout/deposite/txn`,
            { txnID: paymentID },
            { headers }
        )
            .then((res) => {
                // alert(res.data.txnStatus)
                if (res.data.txnStatus === "PAID") {
                    walletUpdate();
                    clearInterval(checkInterval);
                    Swal.fire({
                        title: res.data.msg,
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                    setProcess(false);
                    setNext(1);
                } else if (res.data.txnStatus === "FAILED") {
                    walletUpdate();
                    clearInterval(checkInterval);
                    Swal.fire({
                        title: res.data.msg,
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                    setProcess(false);
                    setNext(1);
                }
                // else if(res.data.txnStatus!="PENDING")
                // {
                //     clearInterval(checkInterval);
                //     Swal.fire({
                //       title: res.data.msg,
                //       icon: 'primary',
                //       confirmButtonText: "OK",
                //   });
                // }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        let access_token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${access_token}` };
        axios.get(baseUrl + `me`, { headers })
            .then((res) => {
                const decryptedData = decrypt(res.data.data)
                setUserAllData(decryptedData);
                setAccount_mail_id(decryptedData.Email);
                setAccount_name(decryptedData.holder_name);
                setAccountPhone(decryptedData.Phone);
            })
            .catch((e) => {
                if (e.response?.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("token");
                    window.location.reload();
                    history.push("/login");
                }
            });

        axios.get(baseUrl + `website/setting`)
            .then((res) => {
                const decryptData = decrypt(res.data.data)
                

                setCashFreeActive(decryptData.CashFreeDeposit);
                setRazorPayActive(decryptData.RazarDeposit);
                setDecentroActive(decryptData.decentroDeposit);
                setPhonePeActive(decryptData.PhonepeDeposit);
                setupiId(decryptData.ManualUpiId);
                // setRazorPayKey(res.data.RazorPayKey);
                setAccountName(decryptData.AccountName);
                setManualPaymentActive(decryptData.ManualDeposit);
                setUpiGatewayActive(decryptData.UpigatwayDeposit);
                setManualUPIQR(decryptData.QrCode);
            })
            .catch((e) => {
                setCashFreeActive(false);
                setRazorPayActive(false);
                setDecentroActive(false);
                setPhonePeActive(true);
                setupiId('');
                // setRazorPayKey(false);
                setManualPaymentActive(false);
                setManualUPIQR(false);
                setUpiGatewayActive(false);

                setAccountName("");
            });

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        ) { setMobile(true); }
        // eslint-disable-next-line
    }, []);


    // const upiId = "paytmqr5fubye@ptys";
    // const upiLink = `upi://pay?pa=${upiId}&pn=${account_name}&am=${global}.00&cu=INR`;

    const copyUPI = () => {
        navigator.clipboard.writeText(upiId);

        Swal.fire({
            title: 'upiId Copied',
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    return (
        <>

            {
                //userAllData && userAllData.verified === 'verified' &&
                <div
                    className="leftContainer bg-white"
                    style={{ minHeight: "100vh", height: "100%", paddingTop: "60px" }}
                >
                    {Boolean(!process) && (
                        <div>
                            {Boolean(next === 1) && (
                                <div className="px-4  py-5">
                                    <div className={`${css.games_section}`}>
                                        <div className="d-flex position-relative align-items-center">
                                            <div className={`${css.games_section_title}`}>
                                                Choose amount to add
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="MultiFormControl_root mt-4 MuiFormControl-fullWidth">
                                            <div className="MuiFormControl_root MuiTextField-root">
                                                <label
                                                    className={`${css.MuiFormLabel_root} MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink`}
                                                    data-shrink="true"
                                                    style={{ fontSize: "0.8rem", fontWeight: "400" }}
                                                >
                                                    Enter Amount
                                                </label>
                                                <div className="MuiInputBase-root MuiInput-root MuiInput_underline jss58 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                                                    <div className="MuiInputAdornment-root MuiInputAdornment-positionStart d-flex w-100">
                                                        <div className="MuiInputAdornment-root MuiInputAdornment-positionStart d-flex align-items-center">
                                                            <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary mt-auto">
                                                                ₹
                                                            </p>
                                                        </div>
                                                        <input
                                                            className={`w3-input input ${css.collapseCard_title} border-bottom text-dark`}
                                                            type="tel"
                                                            id="amountInput"
                                                            style={{ width: "100%", fontSize: "2em" }}
                                                            value={`${global}`}
                                                            onChange={(e) => {
                                                                e.target.value > 0
                                                                    ? e.target.value <= 10000
                                                                        ? setGlobal(parseInt(e.target.value))
                                                                        : setGlobal(10000)
                                                                    : e.target.value < 0
                                                                        ? setGlobal(10)
                                                                        : setGlobal(0);
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>
                                                <p className="MuiFormHelperText-root">
                                                    Min: 10, Max: 10000
                                                </p>
                                            </div>
                                        </div>
                                        <div className={`${css.games_window}`}>
                                            <div
                                                className={`${css.gameCard_container}`}
                                                onClick={() => {
                                                    // console.log(100);
                                                    setGlobal(100);
                                                }}
                                            >
                                                <div className={`${css.add_fund_box}`}>
                                                    <div
                                                        style={{ display: "flex", alignItems: "baseline" }}
                                                    >
                                                        <div
                                                            className={`${css.collapseCard_title} mr-1`}
                                                            style={{ fontSize: "0.9em" }}
                                                        >
                                                            ₹
                                                        </div>
                                                        <div
                                                            className={`${css.collapseCard_title}`}
                                                            style={{ fontSize: "1.5em" }}
                                                        >
                                                            100
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`${css.gameCard_container}`}
                                                onClick={() => {
                                                    // console.log(250);
                                                    setGlobal(250);
                                                }}
                                            >
                                                <div className={`${css.add_fund_box}`}>
                                                    <div
                                                        style={{ display: "flex", alignItems: "baseline" }}
                                                    >
                                                        <div
                                                            className={`${css.collapseCard_title} mr-1`}
                                                            style={{ fontSize: "0.9em" }}
                                                        >
                                                            ₹
                                                        </div>
                                                        <div
                                                            className={`${css.collapseCard_title}`}
                                                            style={{ fontSize: "1.5em" }}
                                                        >
                                                            250
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`${css.gameCard_container}`}
                                                onClick={() => {
                                                    // console.log(500);
                                                    setGlobal(500);
                                                }}
                                            >
                                                <div className={`${css.add_fund_box}`}>
                                                    <div
                                                        style={{ display: "flex", alignItems: "baseline" }}
                                                    >
                                                        <div
                                                            className={`${css.collapseCard_title} mr-1`}
                                                            style={{ fontSize: "0.9em" }}
                                                        >
                                                            ₹
                                                        </div>
                                                        <div
                                                            className={`${css.collapseCard_title}`}
                                                            style={{ fontSize: "1.5em" }}
                                                        >
                                                            500
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`${css.gameCard_container}`}
                                                onClick={() => {
                                                    // console.log(1000);
                                                    setGlobal(1000);
                                                }}
                                            >
                                                <div className={`${css.add_fund_box}`}>
                                                    <div
                                                        style={{ display: "flex", alignItems: "baseline" }}
                                                    >
                                                        <div
                                                            className={`${css.collapseCard_title} mr-1`}
                                                            style={{ fontSize: "0.9em" }}
                                                        >
                                                            ₹
                                                        </div>
                                                        <div
                                                            className={`${css.collapseCard_title}`}
                                                            style={{ fontSize: "1.5em" }}
                                                        >
                                                            1000
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${css.refer_footer}`}>
                                        <div className="d-grid gap-2 col-12 mx-auto">
                                            <button
                                                type="button"
                                                className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                                                onClick={() => {
                                                    global >= 10
                                                        ? setNext(2)
                                                        : alert("Minimum deposit is 10");
                                                }}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {Boolean(next === 2) && (
                                <div className="px-3 py-2">
                                    <div className="pb-3">
                                        <div className={`${css.games_section}`}>
                                            <div className="d-flex position-relative align-items-center justify-content-between">
                                                <div
                                                    className={`${css.games_section_title}`}
                                                    style={{ fontSize: "1.1em" }}
                                                >
                                                    Amount to be added ₹<span>{global}</span>
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={() => setNext(1)}
                                                    className="btn btn-primary text-white font-weight-bold text-uppercase px-2 py-1"
                                                >
                                                    Edit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            background: "rgb(241, 241, 241)",
                                            width: "100%",
                                            height: "10px",
                                            position: "absolute",
                                            left: "0",
                                            right: "0",
                                        }}
                                    ></div>
                                    <div className="d-flex flex-column mt-4">
                                        {/* <div className="games-section-title">
                                                Pay Through UPI
                                        </div>

                                        Boolean(isUpiGatewayActive) &&
                                              <div>
                                              <label htmlFor="username " className="mr-5">
                                                  <i className="far fa-bank mr-2"></i>Account holder name
                                                </label>
                                                <div className="col-12 mb-3 p-0">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    id="account_name"
                                                    placeholder="Enter Account Name"
                                                    name="acname"
                                                    value={account_name}
                                                    onChange={(e) => setAccount_name(e.target.value)}
                                                    required
                                                  />
                                                </div>
                                
                                                <label htmlFor="username " className="mr-5">
                                                  <i className="far fa-bank mr-2"></i>Enter Your Mail ID
                                                </label>
                                                <div className="col-12 mb-3 p-0">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    id="account_mail_id"
                                                    placeholder="Enter Your Mail ID"
                                                    name="mailid"
                                                    value={account_mail_id}
                                                    onChange={(e) => setAccount_mail_id(e.target.value)}
                                                    required
                                                  />
                                                </div>
                                                  </div> */}


                                        {Boolean(isUpiGatewayActive) && (


                                            <div className="card-body mt-2 p-2"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    border: "1px solid #F1948A",
                                                    borderRadius: "7px",
                                                }}
                                            >
                                                <div className="card-title text-danger h4">Attention Please</div>
                                                <p className="card-text h6 text-justify mt-2 mb-0">

                                                    QR कोड का स्क्रीन शॉट लेकर आप पेमेंट कर सकते हो और पेमेंट करने के बाद screen पर जब तक ok लिखा हुआ नही आता है तब तक आपको back नही जाना है  अन्यथा पेमेंट add होने में रुकावट आ सकती है ! थोड़े समय मे UPI पेमेंट सिस्टम चालू हो जायेगा धन्यवाद!
                                                </p>
                                            </div>





                                        )}


                                        {Boolean(isUpiGatewayActive) && (
                                            <div
                                                onClick={() => {
                                                    method.current = "upipay";
                                                    depositUpiGateway(
                                                        "link",
                                                        "upi",
                                                        "upipay",
                                                        "upigateway"
                                                    );
                                                }}
                                                className="add-fund-box mt-3 btn px-0"
                                                style={{ paddingTop: "0px", }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#E8F8F5",
                                                        border: "1px solid #008000",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            height: "60px",
                                                            display: "flex",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            width="40px"
                                                            src="UPI.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30 h3">
                                                            <strong> Paynow</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>

                                        )}








                                        {Boolean(isRazorPayActive) && (
                                            <div
                                                onClick={() => {
                                                    method.current = "upipay";
                                                    displayRazorpayPaymentSdk(
                                                        "link",
                                                        "upi",
                                                        "upipay",
                                                        "razorpay"
                                                    );
                                                }}
                                                className="add-fund-box mt-3"
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            height: "60px",
                                                            display: "flex",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            width="40px"
                                                            src="UPI.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30">
                                                            <strong>Deposit Here</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}



                                        {Boolean(isPhonePeActive) && (
                                            <div
                                                onClick={() => {
                                                    method.current = "upipay";
                                                    phonePeDepositeUpi(
                                                        "link",
                                                        "upi",
                                                        "upipay",
                                                        "Phonepe"
                                                    );
                                                }}
                                                className="add-fund-box my-3 mx-2"
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            height: "60px",
                                                            display: "flex",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            width="40px"
                                                            src="UPI.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30">
                                                            <strong>Deposit PhonePe</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}






                                        {Boolean(isDecentroActive) && (
                                            <div
                                                onClick={() => {
                                                    method.current = "upipay";
                                                    decentroDepositeUpi(
                                                        "link",
                                                        "upi",
                                                        "upipay",
                                                        "decentropay"
                                                    );
                                                }}
                                                className="add-fund-box mt-3"
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            height: "60px",
                                                            display: "flex",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            width="40px"
                                                            src="UPI.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4 mb-5">
                                                        <div className="jss30">
                                                            <strong>Desposit Now</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {Boolean(isManualPaymentActive) && (

                                            <div className="container w-100 p-2">
                                                <div className="row rounded-lg bg-secondary mb-3 m-0 py-4">
                                                    <div className="col d-flex justify-content-center align-items-center">
                                                        <div className="rounded-sm">
                                                            <img
                                                                src={baseUrl + isManualUPIQR}
                                                                alt="UPI QR"
                                                                className="img-fluid"
                                                                style={{ maxHeight: "150px" }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col pt-2">
                                                        <h6 style={{ textAlign: "justify", fontSize: "12px", lineHeight: "150%" }}>
                                                            <span className="block">Method 1: </span>
                                                            QR कोड स्कैन करें और भुगतान करें
                                                        </h6>
                                                        <h6 style={{ textAlign: "justify", fontSize: "12px", lineHeight: "150%" }}>
                                                            <span className="block">Method 2: </span>
                                                            UPI आईडी कॉपी करें और अपने फोनपे या पेटीएम से भुगतान करें
                                                        </h6>
                                                        <h6 style={{ textAlign: "justify", fontSize: "12px", lineHeight: "150%" }}>
                                                            <span className="block">Method 3: </span>
                                                            "Pay now" पर क्लिक करें और किसी भी UPI APP द्वारा भुगतान करें
                                                        </h6>
                                                    </div>
                                                </div>


                                                <form>
                                                    {/* UPI ID Label and Input */}
                                                    <div className="form-group">
                                                        <label htmlFor="upiId">
                                                            <strong>UPI ID</strong>
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className="form-control border-0"
                                                                // style={{border:"1px solid red"}}
                                                                id="upiId"
                                                                value={upiId}
                                                                readOnly
                                                            />

                                                            <div className="input-group-append">
                                                                <button
                                                                    className="btn btn-outline-secondary"
                                                                    type="button"
                                                                    onClick={copyUPI}
                                                                >
                                                                    <i className="fa fa-copy"></i> {/* Font Awesome icon for copy */}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*<div className="input-group-append w-full d-flex">
                                                        <a href={upiLink} style={{ width: "100%" }}>
                                                            <button className="btn btn-primary text-light font-bold" style={{ width: "100%" }} type="button">
                                                                Pay Now
                                                            </button>
                                                        </a>
                                                    </div>*/}
                                                </form>
                                            </div>


                                        )}



                                        {Boolean(isManualPaymentActive) && (
                                            <>
                                                <hr />
                                                <div className="col pt-2">
                                                    <h6 style={{ textAlign: "justify", fontSize: "12px", lineHeight: "150%", color: "#C0C0C0" }}>

                                                        भुगतान सफल होने के बाद Transaction Id दर्ज करें or submit करें। आपका भुगतान सफलतापूर्वक जोड़ दिया जाएगा
                                                    </h6>
                                                </div>

                                                <form
                                                    onSubmit={ManualPayment}
                                                    method="POST"
                                                    encType="multipart/form-data"

                                                >
                                                    <div className="mt-2 px-2">
                                                        {/* <label htmlFor="username " className="mr-5">
                                                        <i className="far fa-bank mr-2"></i>Enter Transaction ID
                                                    </label> */}
                                                        <div className="col-12 mb-3 p-0">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="referenceId"
                                                                placeholder="Enter Transaction ID"
                                                                name="referenceId"
                                                                value={upiUtrNum}
                                                                onChange={(e) => setupiUtrNum(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* <div
                                                    className="add-fund-box mt-3 px-4"
                                                    style={{ paddingTop: "0px", height: "60px" }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center justify-content-center"
                                                        style={{
                                                            backgroundColor: "#fafafa",
                                                            border: "1px solid #e0e0e0",
                                                            borderRadius: "7px",
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center  p-2 "
                                                            style={{ textAlign: "center" }}
                                                        >
                                                            <img
                                                                width="20px"
                                                                src="images/upload.png"
                                                                className="img-fluid align-self-center"
                                                                alt=""
                                                                style={{
                                                                    marginLeft: "7px",
                                                                    paddingBottom: "10px",
                                                                    paddingLeft: "3px",
                                                                    paddingTop: "5px",
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="ml-2 position-relative">
                                                            <div className="">
                                                                <strong>Upload Screenshot</strong>
                                                            </div>
                                                            <div className="jss31 d-flex ">
                                                                <input
                                                                    type="file"
                                                                    name="Screenshot"
                                                                    onChange={handleChange}
                                                                    accept="image/*"
                                                                    style={{ display: "flex", position: "absolute", top: "0", opacity: "0" }}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> */}


                                                    <div className="mt-2 text-center px-2">
                                                        <button type="submit"
                                                            className="btn btn-success text-light text-bold"
                                                            style={{ width: "100%" }}
                                                            disabled={Boolean(submitBtn) ? false : true}
                                                        >
                                                            Submit
                                                        </button>
                                                        {Boolean(submitBtn) ? "" : "Reload Page"}
                                                    </div>
                                                    {scrnshot1 &&
                                                        <div style={{ width: "100%", height: "400px" }}>
                                                            <img src={scrnshot1} alt="scrnshot" style={{ width: "100%" }} />
                                                        </div>
                                                    }
                                                </form>
                                            </>
                                        )}

                                        {Boolean(isMobile) && Boolean(isCashFreeActive) && (
                                            <div>
                                                <div
                                                    onClick={() => {
                                                        method.current = "gpay";
                                                        deposit("link", "upi", "gpay");
                                                    }}
                                                    className="add-fund-box mt-3"
                                                    style={{ paddingTop: "0px", height: "60px" }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            backgroundColor: "#fafafa",
                                                            border: "1px solid #e0e0e0",
                                                            borderRadius: "7px",
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                height: "60px",
                                                                display: "flex",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <img
                                                                width="40px"
                                                                src="gpay-logo.png"
                                                                alt=""
                                                                style={{
                                                                    marginLeft: "7px",
                                                                    paddingBottom: "10px",
                                                                    paddingLeft: "3px",
                                                                    paddingTop: "5px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-center flex-column ml-4">
                                                            <div className="jss30">
                                                                <strong>G-Pay</strong>
                                                            </div>
                                                            <div className="jss31"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={() => {
                                                        method.current = "phonepe";
                                                        deposit("link", "upi", "phonepe");
                                                    }}
                                                    className="add-fund-box mt-3"
                                                    style={{ paddingTop: "0px", height: "60px" }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            backgroundColor: "#fafafa",
                                                            border: "1px solid #e0e0e0",
                                                            borderRadius: "7px",
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                height: "60px",
                                                                display: "flex",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <img
                                                                width="40px"
                                                                src="/phonepe-logo.png"
                                                                alt=""
                                                                style={{
                                                                    marginLeft: "7px",
                                                                    paddingBottom: "10px",
                                                                    paddingLeft: "3px",
                                                                    paddingTop: "5px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-center flex-column ml-4">
                                                            <div className="jss30">
                                                                <strong>PhonePe</strong>
                                                            </div>
                                                            <div className="jss31"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        method.current = "paytm";
                                                        deposit("link", "upi", "paytm");
                                                    }}
                                                    className="add-fund-box mt-3"
                                                    style={{ paddingTop: "0px", height: "60px" }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            backgroundColor: "#fafafa",
                                                            border: "1px solid #e0e0e0",
                                                            borderRadius: "7px",
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex align-items-center"
                                                            style={{
                                                                height: "60px",
                                                                display: "flex",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <img
                                                                width="40px"
                                                                src="/paytm-logo.png"
                                                                alt=""
                                                                style={{
                                                                    marginLeft: "7px",
                                                                    paddingBottom: "10px",
                                                                    paddingLeft: "3px",
                                                                    paddingTop: "5px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="d-flex justify-content-center flex-column ml-4">
                                                            <div className="jss30">
                                                                <strong>Paytm UPI</strong>
                                                            </div>
                                                            <div className="jss31"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {Boolean(!isMobile) && Boolean(isCashFreeActive) && (
                                            <div
                                                className="add-fund-box mt-3"
                                                onClick={() => {
                                                    deposit("qrcode", "upi");
                                                }}
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{ height: "60px" }}
                                                    >
                                                        <img
                                                            width="45px"
                                                            src="/qr-scan.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingLeft: "3px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30">
                                                            <strong>Scan QR Code</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {Boolean(isCashFreeActive) && (
                                            <div className="games-section-title mt-3">
                                                Other Options
                                            </div>
                                        )}

                                        {Boolean(isCashFreeActive) && (
                                            <div
                                                className="add-fund-box mt-3"
                                                onClick={() => {
                                                    setNext(4);
                                                }}
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{ height: "60px" }}
                                                    >
                                                        <img
                                                            width="45px"
                                                            src="all-wallets.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30">
                                                            <strong>Other Wallets</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {Boolean(isCashFreeActive) && (
                                            <div
                                                className="add-fund-box mt-3"
                                                onClick={() => {
                                                    setNext(5);
                                                }}
                                                style={{ paddingTop: "0px", height: "60px" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        backgroundColor: "#fafafa",
                                                        border: "1px solid #e0e0e0",
                                                        borderRadius: "7px",
                                                    }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{ height: "60px" }}
                                                    >
                                                        <img
                                                            width="45px"
                                                            src="/bank1.png"
                                                            alt=""
                                                            style={{
                                                                marginLeft: "7px",
                                                                paddingBottom: "10px",
                                                                paddingLeft: "3px",
                                                                paddingTop: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="d-flex justify-content-center flex-column ml-4">
                                                        <div className="jss30">
                                                            <strong>Net Banking</strong>
                                                        </div>
                                                        <div className="jss31"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {Boolean(next === 3) && (
                        <div className="d-flex justify-content-center align-items-center mt-5">
                            <img src={qrCode} alt="img" />
                        </div>
                    )}
                    {Boolean(next === 4) && (
                        <div className="px-4 py-3">
                            <div className="pb-3">
                                <div className={`${css.games_section}`}>
                                    <div className="d-flex position-relative align-items-center justify-content-between">
                                        <div
                                            className={`${css.games_section_title}`}
                                            style={{ fontSize: "1.1em" }}
                                        >
                                            Amount to be added ₹<span>{global}</span>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => setNext(1)}
                                            className="btn btn-primary text-white font-weight-bold text-uppercase px-2 py-1"
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    background: "rgb(241, 241, 241)",
                                    width: "100%",
                                    height: "10px",
                                    position: "absolute",
                                    left: "0",
                                    right: "0",
                                }}
                            ></div>
                            <div className="d-flex flex-column mt-4">
                                <div className="games-section-title">Pay Through</div>
                                <div
                                    className="add-fund-box mt-3"
                                    style={{ paddingTop: "0px", height: "60px" }}
                                >
                                    <div
                                        className="d-flex align-items-center"
                                        style={{
                                            backgroundColor: "#fafafa",
                                            border: "1px solid #e0e0e0",
                                            borderRadius: "7px",
                                        }}
                                    >
                                        <div
                                            className="d-flex align-items-center"
                                            style={{ height: "60px" }}
                                        >
                                            <img
                                                width="45px"
                                                src="all-wallets.png"
                                                alt=""
                                                style={{
                                                    marginLeft: "7px",
                                                    paddingLeft: "3px",
                                                }}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center flex-column ml-4">
                                            <div className="jss30">
                                                <strong>Other Wallets</strong>
                                            </div>
                                            <div className="jss31"></div>
                                        </div>
                                        <div className="d-flex justify-content-center ml-auto mr-3">
                                            <button
                                                type="button"
                                                onClick={() => setNext(2)}
                                                className="btn btn-primary text-white font-weight-bold text-uppercase px-2 py-1"
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <select
                                className="form-control mt-4"
                                style={{
                                    border: "1px solid rgb(224, 224, 224)",
                                    outline: "none",
                                    borderRadius: "5px",
                                    background: "rgb(250, 250, 250)",
                                    height: "3rem",
                                }}
                                onChange={(e) => {
                                    setWalletOption(e.target.value);
                                }}
                            >
                                <option value="airtel">Airtel Money</option>
                                <option value="freecharge">Freecharge</option>
                                <option value="mobikwik">Mobikwik</option>
                                <option value="ola">Ola Money</option>
                                <option value="jio">Reliance Jio Money</option>
                            </select>
                            <div className={`${css.refer_footer}`}>
                                <div className="d-grid gap-2 col-12 mx-auto">
                                    <button
                                        type="button"
                                        className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                                        onClick={() => {
                                            deposit("link", "app");
                                        }}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}


                    {Boolean(next === 5) && (
                        <div className="px-4 py-3">
                            <div className="pb-3">
                                <div className={`${css.games_section}`}>
                                    <div className="d-flex position-relative align-items-center justify-content-between">
                                        <div
                                            className={`${css.games_section_title}`}
                                            style={{ fontSize: "1.1em" }}
                                        >
                                            Amount to be added ₹<span>{global}</span>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => setNext(1)}
                                            className="btn btn-primary text-white font-weight-bold text-uppercase px-2 py-1"
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    background: "rgb(241, 241, 241)",
                                    width: "100%",
                                    height: "10px",
                                    position: "absolute",
                                    left: "0",
                                    right: "0",
                                }}
                            ></div>
                            <div className="d-flex flex-column mt-4">
                                <div className="games-section-title">Pay Through</div>
                                <div
                                    className="add-fund-box mt-3"
                                    style={{ paddingTop: "0px", height: "60px" }}
                                >
                                    <div
                                        className="d-flex align-items-center"
                                        style={{
                                            backgroundColor: "#fafafa",
                                            border: "1px solid #e0e0e0",
                                            borderRadius: "7px",
                                        }}
                                    >
                                        <div
                                            className="d-flex align-items-center"
                                            style={{ height: "60px" }}
                                        >
                                            <img
                                                width="45px"
                                                src="/bank1.png"
                                                alt=""
                                                style={{
                                                    marginLeft: "7px",
                                                    paddingLeft: "3px",
                                                }}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center flex-column ml-4">
                                            <div className="jss30">
                                                <strong>Net Banking</strong>
                                            </div>
                                            <div className="jss31"></div>
                                        </div>
                                        <div className="d-flex justify-content-center ml-auto mr-3">
                                            <button
                                                type="button"
                                                onClick={() => setNext(2)}
                                                className="btn btn-primary text-white font-weight-bold text-uppercase px-2 py-1"
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <select
                                className="form-control mt-4"
                                style={{
                                    border: "1px solid rgb(224, 224, 224)",
                                    outline: "none",
                                    borderRadius: "5px",
                                    background: "rgb(250, 250, 250)",
                                    height: "3rem",
                                }}
                                onChange={(e) => {
                                    setBankCode(e.target.value);
                                }}
                            >
                                <option value="3003">Axis Bank</option>
                                <option value="3005">Bank of Baroda - Retail Banking</option>
                                <option value="3006">Bank of India</option>
                                <option value="3007">Bank of Maharashtra</option>
                                <option value="3009">Canara Bank</option>
                                <option value="3010">Catholic Syrian Bank</option>
                                <option value="3011">Central Bank of India</option>
                                <option value="3012">City Union Bank</option>
                                <option value="3016">Deutsche Bank</option>
                                <option value="3017">DBS Bank Ltd</option>
                                <option value="3018">DCB Bank - Personal</option>
                                <option value="3019">Dhanlakshmi Bank</option>
                                <option value="3020">Federal Bank</option>
                                <option value="3021">HDFC Bank</option>
                                <option value="3022">ICICI Bank</option>
                                <option value="3023">IDBI Bank</option>
                                <option value="3024">IDFC FIRST Bank</option>
                                <option value="3026">Indian Bank</option>
                                <option value="3027">Indian Overseas Bank</option>
                                <option value="3028">IndusInd Bank</option>
                                <option value="3029">Jammu and Kashmir Bank</option>
                                <option value="3030">Karnataka Bank Ltd</option>
                                <option value="3031">Karur Vysya Bank</option>
                                <option value="3032">Kotak Mahindra Bank</option>
                                <option value="3033">
                                    Laxmi Vilas Bank - Retail Net Banking
                                </option>
                                <option value="3037">Punjab & Sind Bank</option>
                                <option value="3038">
                                    Punjab National Bank - Retail Net Banking
                                </option>
                                <option value="3039">RBL Bank</option>
                                <option value="3040">Saraswat Bank</option>
                                <option value="3042">South Indian Bank</option>
                                <option value="3043">Standard Chartered Bank</option>
                                <option value="3044">State Bank Of India</option>
                                <option value="3052">Tamilnad Mercantile Bank Ltd</option>
                                <option value="3054">UCO Bank</option>
                                <option value="3055">Union Bank of India</option>
                                <option value="3058">Yes Bank Ltd</option>
                                <option value="3060">Bank of Baroda - Corporate</option>
                                <option value="3061">Bank of India - Corporate</option>
                                <option value="3062">DCB Bank - Corporate</option>
                                <option value="3064">Lakshmi Vilas Bank - Corporate</option>
                                <option value="3065">Punjab National Bank - Corporate</option>
                                <option value="3066">State Bank of India - Corporate</option>
                                <option value="3067">Union Bank of India - Corporate</option>
                                <option value="3071">Axis Bank Corporate</option>
                                <option value="3072">Dhanlaxmi Bank Corporate</option>
                                <option value="3073">ICICI Corporate Netbanking</option>
                                <option value="3074">Ratnakar Corporate Banking</option>
                                <option value="3075">Shamrao Vithal Bank Corporate</option>
                                <option value="3076">Equitas Small Finance Bank</option>
                                <option value="3077">Yes Bank Corporate</option>
                                <option value="3079">Bandhan Bank- Corporate banking</option>
                                <option value="3080">
                                    Barclays Corporate- Corporate Banking - Corporate
                                </option>
                                <option value="3081">Indian Overseas Bank Corporate</option>
                                <option value="3083">City Union Bank of Corporate</option>
                                <option value="3084">HDFC Corporate</option>
                                <option value="3086">Shivalik Bank</option>
                                <option value="3087">AU Small Finance</option>
                                <option value="3088">Bandhan Bank - Retail Net Banking</option>
                                <option value="3041">Shamrao Vitthal Co-operative Bank</option>
                                <option value="3051">Tamil Nadu State Co-operative Bank</option>
                                <option value="3089">Utkarsh Small Finance Bank</option>
                                <option value="3090">
                                    The Surat People’s Co-operative Bank Limited
                                </option>
                                <option value="3091">
                                    Gujarat State Co-operative Bank Limited
                                </option>
                                <option value="3092">HSBC Retail Netbanking</option>
                                <option value="3102">Jana Small Finance Bank</option>
                            </select>
                            <div className={`${css.refer_footer}`}>
                                <div className="d-grid gap-2 col-12 mx-auto">
                                    <button
                                        type="button"
                                        className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                                        onClick={() => {
                                            deposit("link", "netbanking");
                                        }}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {Boolean(process) && (
                        <div className="loaderReturn" style={{ zIndex: "99" }}>
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}Images/LandingPage_img/loaderhi.gif`}
                                style={{ width: "100%" }}
                                alt="img"
                            />
                        </div>
                    )}
                </div>
            }
            {/* <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>

        <div className="container px-3 mt-5 py-5" style={{ height: "10px" }}>
          <div className="row">

            <div className="col mx-auto">
              <div className="card text-center mt-3">

                {userAllData && userAllData.verified === 'unverified' && <div style={{ height: "100px" }}>
                  <Link to='/Kyc2'>

                    <picture className="ml-3">
                      <img src="/images/alert.svg" alt="" width="32px" className="mt-4" />
                    </picture>
                    <div className="ml-1 mt-2 mytext text-muted ">
                      Complete KYC to Deposit Amount
                    </div>
                  </Link>
                </div>}
                {userAllData && userAllData.verified === 'pending' && <div style={{ height: "100px" }}>
                  <picture className="ml-3">
                    <img src="/images/alert.svg" alt="" width="32px" className="mt-4" />
                  </picture>
                  <div className="ml-1 mt-2 mytext text-muted ">
                    Please wait your kyc under process
                  </div>
                </div>}

              </div>
            </div>
          </div>
        </div>
      </div> */}
        </>
    );
};
export default Addcase;
