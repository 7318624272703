import React, { useEffect, useState } from 'react'
import css from "../css/Refer.module.css"
import { Link } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2';
import { decrypt } from '../utils/cryptoUtils';




const Refer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [user, setUser] = useState()
  const Cashheader = () => {
    let access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.get(baseUrl + `me`, { headers })
      .then((res) => {
        const decryptData = decrypt(res.data.data);  
          
        setUser(decryptData)
        // console.log(res.data);
        Allgames(decryptData.referral_code)
      }).catch((e) => {
        alert(e.msg)
      })

  }

//   console.log(process.env.REACT_APP_PUBLIC_URL, process.env.REACT_APP_PUBLIC_URL, "error here")  


  const [cardData, setGame] = useState([])

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    await axios.get(baseUrl + `referral/code/${id}`, { headers })
      .then((res) => {
        setGame(res.data)
        console.log(res.data)

      })

  }
  // eslint-disable-next-line
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    try {
      const response = await fetch(baseUrl + "settings/data");
  
      // Check if the response is empty (status 204: No Content)
      if (response.status === 204 || response.headers.get("content-length") === "0") {
        throw new Error("No content in response");
      }
  
      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Try parsing the JSON
      const data = await response.json();
  
      // Now decrypt the data
      const decryptData = decrypt(data.data); // Assuming the encrypted data is inside `data.data`
      // console.log(decryptData.data);
      setWebsiteSettings(decryptData.data);
  
      // You can further handle the decrypted data here
      // setWebsiteSettings(decryptData);
    } catch (error) {
      console.log("Error fetching data:", error);
      document.title = (WebSitesettings) ? WebSitesettings.WebTitle : '';
    }
  };
  

  useEffect(() => {
    Cashheader()
    fetchData();
    //eslint-disable-next-line
  }, [])


  const copyCode = (e) => {
    // console.log(Game.Room_code);
    navigator.clipboard.writeText(user.referral_code);

    Swal.fire({
      position: 'center',
      icon: 'success',
      type: 'success',
      title: 'Room Code Copied',
      showConfirmButton: false,
      timer: 1200,


    });

  }

  if (user === undefined) {
    return null
  }

  return (
    <>

      <div>
        <div className='leftContainer' style={{ height: '100vh' }}>

          <div className={`${css.center_xy} pt-5`}>
            <picture className="mt-1">
              <img alt="img" width="226px" src={process.env.REACT_APP_PUBLIC_URL + '/Images/refer/refer.png'} className="snip-img" />
            </picture>
            <div className="mb-1">
              <div className="font-15">
                Earn now unlimited
                <span aria-label="party-face" >
                  🥳
                </span>
              </div>
              <div className="d-flex justify-content-center">
                Refer your friends now!
              </div>
              <div className="mt-3 text-center font-9">
                Current Earning:
                <b>
                  {user.referral_wallet}
                </b>
                <Link className="ml-2" to="/Redeem">
                  Redeem
                </Link>
              </div>
              <div className="text-center font-9">
                Total Earned:
                <b>
                  {user.referral_earning}
                </b>
              </div>
              <div className={`${css.progress}`}>
                <div className={`${css.progress_bar} ${css.progress_bar_striped} ${css.bg_success}`} aria-valuenow={user.referral_earning} aria-valuemax={10000} style={{ width: `${user.referral_earning * 100 / 10000}%` }}>
                </div>
              </div>
              <div className="font-9">
                <span >
                  Max: ₹10,000
                </span>
                <Link className="float-right" to="/update-pan">
                  Upgrade Limit
                </Link>
              </div>
              <div className={`${css.text_bold} mt-3 text-center`}>
                Your Refer Code: {user.referral_code}


                <i className="ri-clipboard-fill ml-2 " style={{ fontSize: "20px", color: "#007bff" }} onClick={(e) => copyCode(e)}></i>


              </div>

              <div className="d-flex justify-content-center">
                Total Refers:&nbsp;
                <b>
                  {cardData && cardData}
                </b>
              </div>
            </div>
          </div>

          <div className="mx-3 my-3">
            <div className={`${css.font_11} ${css.text_bold}`}>
              Refer &amp; Earn Rules
            </div>
            <div className="d-flex align-items-center m-3">
              <picture>
                <img alt="img" width="82px" src={process.env.REACT_APP_PUBLIC_URL + '/Images/refer/giftbanner.png'} className="snip-img" />
              </picture>
              <div className={`${css.font_9} mx-3`} style={{ width: '63%' }}>
                <div>
                  When your friend signs up on Our website or App from your referral link,
                </div>
                <div className={`${css.font_8} ${css.c_green} mt-2`}>
                  You get &nbsp;
                  <strong>
                    2% Commission
                  </strong>
                  on your &nbsp;
                  <strong>
                    referral's winnings.
                  </strong>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center m-3">
              <picture>
                <img alt="img" width="82px" src={process.env.REACT_APP_PUBLIC_URL + '/Images/refer/banner.png'} className="snip-img" />
              </picture>
              <div className={`${css.font_9} mx-3`} style={{ width: '63%' }}>
                <div >
                  Suppose your referral plays a battle for ₹10000 Cash,
                </div>
                <div className={`${css.font_8} ${css.c_green} mt-2`}>
                  You get &nbsp;
                  <strong>
                    ₹100 Cash
                  </strong>
                  <strong>
                  </strong>
                </div>
              </div>
            </div>

          </div>
          <div className="mx-3 my-3">
            <a href={`https://wa.me?text=Play Ludo and earn ₹10000 daily. Commission Charge - 5% Only Referral - 2% On All Games 24x7 Live Chat Support Instant Widhdrawl Via UPI/BANK Register Now: ${process.env.REACT_APP_PUBLIC_URL}/login/${user.referral_code}  Register Now, My refer code is ${user.referral_code}.`} style={{ width: "100%" }}>
              <button className="bg-success refer-button cxy w-100">
                <img alt="img" width="20px" src={process.env.REACT_APP_PUBLIC_URL + '/whatsapp.png'} className="snip-img" /> &nbsp;
                Share in Whatsapp
              </button>
            </a>
          </div>

          <div className="mx-3 my-3">
            <a href={`https://telegram.me/share/url?url=Play Ludo and earn ₹10000 daily. Commission Charge - 5% Only Referral - 2% On All Games 24x7 Live Chat Support Instant Widhdrawl Via UPI/BANK Register Now: ${process.env.REACT_APP_PUBLIC_URL}/login/${user.referral_code}  Register Now, My refer code is ${user.referral_code}.`} style={{ width: "100%" }}>
              <button className="bg-primary refer-button cxy w-100">
                <img alt="img" width="20px" src={process.env.REACT_APP_PUBLIC_URL + '/telegram.svg'} className="snip-img" />&nbsp;
                Share in Telegram
              </button>
            </a>
          </div>

          <div className="mx-3 my-3">___
          </div>


          {/*<div className="mx-3 my-3">
        <a onClick={() => handleClick(referLink)}>
                <button className="bg-green refer-button cxy w-100">
                <img alt="img" width="20px" src={process.env.REACT_APP_PUBLIC_URL + 'clipboard.svg'} className="snip-img" />&nbsp;
                    Copy To clipboard
                </button>
            </a>
        </div>
        
        
        <div className={`${css.refer_footer} pt-2 pb-1`}>
        
        <a href={`https://wa.me?text=Play Ludo and earn ₹10000 daily. Commission Charge - 5% Only Referral - 2% On All Games 24x7 Live Chat Support Instant Widhdrawl Via UPI/BANK Register Now: ${process.env.REACT_APP_PUBLIC_URL}login/${user.referral_code}  Register Now, My refer code is ${user.referral_code}.`} style={{width:"100%"}}>
          <button className="bg-success refer-button cxy w-100">
            Share in Whatsapp
          </button>
        </a>
          

        </div>*/}

        </div>

      </div>
    </>
  )
}

export default Refer
